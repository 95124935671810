<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>


        <sweet-modal ref="openlinkmodal" @close="closeNested" title="Log Into Facebook">

            <div v-if="showNested && !showAccountModal">

                <p>Here is the link to log into facebook</p>

                <a :href="currentLink" target="_blank" @click="showNested=false;$refs.openlinkmodal.close();">Open Facebook</a>

                or copy and paste the link below into your browser

                <br>

                <br>

                <a-button type="primary" @click="copyToClipboard(currentLink)">Copy To Clipboard</a-button>


            </div>
        
            
        
        </sweet-modal>


        <sweet-modal ref="accountlogoutmodal" @close="closeNested" title="Log Out of Account">
        
            <div v-if="showNested && showAccountModal">
        
                <p>Are you sure you want to log out of this account now? 
                    This will also log out all linked Facebook and Instagram Pages. 
                    All pending scheduled posts will also be canceled. 
                    You will have to log in again to use the account.
                </p>

                <a-row>

                    <a-col :span="10" style="margin: 9px;">
                        <a-button  type="danger" @click="completeLogout" block>Yes</a-button>
                    </a-col>

                    <a-col :span="10" style="margin: 9px;">
                        <a-button  type="primary" @click="$refs.accountlogoutmodal.close()" block>No</a-button>
                    </a-col>
                </a-row>

                <a-col :span="24">
                        <vue-loaders-ball-beat v-if="loading" color="green" scale="1"></vue-loaders-ball-beat>
                </a-col>
    
        
            </div>
        
        </sweet-modal>

         

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading' v-model="searchTerm">
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="secondary" block style="margin-left: 9px;" @click="requestAddFbAccount" :disabled="!current_account_id">
                    <i class="fa fa-facebook" style="margin-right: 7px;"></i>{{ current_account_id? 'Login With Facebook' : 'Select Virtual Account First' }}
                </a-button>
            </a-col>
            
        </a-row>

         

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="facebookAccounts.length<1">No items to display, check that you have selected a virtual account in the right drawer. Click on the top right gear icon</p>

			<!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="accountTableColumns" :data-source="facebookAccounts" :pagination="false" :loading="loading">
                    <span slot="id" slot-scope="text, record">
                        
                        <i class="fa fa-user" aria-hidden="true" style="margin-right:9px;"></i>
                        <span class="ml-2">{{ record.external_id }}</span>
                    </span>

                    <span slot="name" slot-scope="text, record">
                        {{ record.name }}
                    </span>

                    <span slot="account_type" slot-scope="text, record">
                        {{ accountTypeDisplay(record.account_type) }}
                    </span>

                    <span slot="expires_at_ts" slot-scope="text, record">
                        {{ expiresInDisplay(record.expires_at_ts) }}
                    </span>
                    
                    <span slot="expired" slot-scope="text, record">
                        <a-button type="primary" @click="openPages(record.id)" style="margin-right: 7px;">View All Pages</a-button>

                        <a-button type="secondary" :disabled="record.expired" @click="doLogout(record)" style="margin-left: 7px;">{{record.expired?'Account Logged out': 'Account Logout'}}</a-button>
                    </span>
                </a-table>

			</a-col>
			<!-- / Authors Table Column -->

            

		</a-row>
		<!-- / Authors Table -->

        <br>       

	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetCustomer from '../components/Widgets/WidgetCustomer' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';

    import {getCurrentUserAccountId, saveCurrentUserAccountId, saveCurrentUseraccountRole, getCurrentUseraccountId, getCurrentUseraccountRole, getUserInfo, clearUserInfo, isUserLoggedIn} from '../services/v1/data_methods';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetCustomer,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},

        beforeDestroy() {
            this.$eventHub.$off("currentAccountChanged");

            this.$eventHub.$off("loadedPreferences");

            if(this.periodic1) {
                clearInterval(this.periodic1);
            
            }
        },
        
        async mounted() {


            
            // console.log(this.$store.state)

			this.preferences = this.$store.getters.getPreferences;

			this.current_account_id = this.preferences.current_virtual_account;

            // console.log('current_account_idcc', this.current_account_id);


            if(this.current_account_id == null) {
                this.error = 'Nothing to display. Please select a virtual account on the side menu first.';
                this.loading = false;
                return
            }

            const projectFacebookAccountsRes = await fetchDataJSON(`users/accounts/${this.current_account_id}` + '/saved_accounts/list?account_type=1&page=' + this.currentPage , true, this.$store);

            if(!projectFacebookAccountsRes.Ok) {
                this.error = projectFacebookAccountsRes.data;
                this.loading = false;
                return
            }
            
            this.facebookAccounts = projectFacebookAccountsRes.data.accounts;

             
            this.total_facebookAccounts =10;

            this.$eventHub.$on("currentAccountChanged", async (item) => {
                console.log('currentAccountChanged indir', item);

                this.current_account_id = item.value;

                const projectFacebookAccountsRes = await fetchDataJSON('users/accounts/' + this.current_account_id + '/saved_accounts/list?account_type=1&page=' + this.currentPage, true, this.$store);

                this.facebookAccounts = projectFacebookAccountsRes.data.accounts;
            });

            // periodically fetch the facebook accounts

            this.periodic1 = setInterval(async () => {
                const projectFacebookAccountsRes = await fetchDataJSON('users/accounts/' + this.current_account_id + '/saved_accounts/list?account_type=1&page=' + this.currentPage, true, this.$store);

                // console.log('periodic1', projectFacebookAccountsRes);

                if(projectFacebookAccountsRes.data.accounts.length != this.facebookAccounts.length) {
                    this.facebookAccounts = projectFacebookAccountsRes.data.accounts;
                }
                
            }, 10000);


            this.$eventHub.$on("loadedPreferences", async (item) => {
				console.log('loadedPreferences fb acc loaded evt', item);

				this.preferences = this.$store.getters.getPreferences;

				this.current_account_id = this.preferences.current_virtual_account;

                const projectFacebookAccountsRes = await fetchDataJSON('users/accounts/' + this.current_account_id + '/saved_accounts/list?account_type=1&page=' + this.currentPage, true, this.$store);

                this.facebookAccounts = projectFacebookAccountsRes.data.accounts;

			});

            this.loading = false;
        },
        methods: {
            doLogout(record) {
                this.showNested = true;

                this.showAccountModal = true;

                this.$refs.accountlogoutmodal.open();

                this.selectedFBAccount = record;
            },
            async completeLogout() {
                this.loading = true;

                console.log('completeLogout', this.selectedFBAccount);

                const res = await fetchPostJSON('users/saved_accounts/' + this.selectedFBAccount.id + '/logout', {
                        key: 'current_virtual_account',
                    }, true, this.$store);

                console.log('completeLogout', res);
 
                if (res.Ok) {
                    this.$message.success('Logout successful');

                    const projectFacebookAccountsRes = await fetchDataJSON('users/accounts/' + this.current_account_id + '/saved_accounts/list?account_type=1&page=' + this.currentPage, true, this.$store);

                    this.facebookAccounts = projectFacebookAccountsRes.data.accounts;

                    this.$refs.accountlogoutmodal.close();
                }

                else {
                    this.$message.error('Logout failed');

                }

                this.loading = false;
            },
            openPages(id) {
            this.$router.push('/facebookaccounts/' + id + '/facebookpages');
        },
            expiresInDisplay(expires_at_ts) {
                const time_left = expires_at_ts * 1000 - Date.now();

                if (time_left / 1000 / 60 / 60 / 24 > 1) {
                    return Math.floor(time_left / 1000 / 60 / 60 / 24) + ' days';
                }

                if (time_left / 1000 / 60 / 60 > 1) {
                    return Math.floor(time_left / 1000 / 60 / 60) + ' hours';
                }

                if (time_left / 1000 / 60 > 1) {
                    return Math.floor(time_left / 1000 / 60) + ' minutes';
                }

                else {
                    return Math.floor(time_left / 1000) + ' seconds';
                }
            },
            accountTypeDisplay(account_type) {
                return account_type == 1 ? 'Facebook' : 'TikTok';
            },

            async copyToClipboard(text) {
                
                if (!navigator.clipboard) {
                    // Clipboard API not available
                    this.$message.error('Clipboard API not available');
                    return
                }
                await navigator.clipboard.writeText(text)
                console.log('Copied to clipboard');

                this.$refs.openlinkmodal.close();

                this.$message.success('Copied to clipboard');
                
            },
            async requestAddFbAccount() {
                this.loading = true;

                const res = await fetchDataJSON('fb/' + this.current_account_id + '/request_token', true, this.$store);


                if (res.Ok) {
                    this.$message.success('Request sent successfully, opening Facebook login page');

                    this.currentLink = res.data.login_url;

                

                    // this.showNested = true;

                    // this.$refs.openlinkmodal.open();

                    window.open(res.data.login_url, '_blank');
                }

                else {
                    this.$message.error('Request failed');
                }

                this.loading = false;
            },
            async onPageChange(page) {
                console.log(page);
                this.currentPage = page;

                this.loading = true;

                const projectFacebookAccountsRes = await fetchDataJSON('users/accounts/' + this.current_account_id + '/saved_accounts/list?account_type=1&page=' + this.currentPage, true, this.$store);

                this.facebookAccounts = projectFacebookAccountsRes.data.accounts;

                this.loading = false;
            },
            initCreateProjectChannelModal() {
                this.showNested = true;
                this.$refs.createprojectchannelmodal.open();
            },
            closeNested() {
                this.showNested = false;
            }, 
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectFacebookAccountsRes = await fetchDataJSON('projects/' + this.current_account_id + '/FacebookAccounts/list?term=' + value , true, this.$store);

                this.facebookAccounts = projectFacebookAccountsRes.data.facebookAccounts;

                this.searchLoading = false;
            },
        },
		data() {
			return {

                facebookAccounts: [],

                showAccountModal: true,

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                currentPage: 1,

                accountTableColumns: [
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        scopedSlots: { customRender: 'id' },
                    },
                    {
                        title: 'name',
                        dataIndex: 'name',
                        key: 'name',
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: 'expires in',
                        dataIndex: 'expires_at_ts',
                        key: 'expires_at_ts',
                        scopedSlots: { customRender: 'expires_at_ts' },
                    },
                    {
                        title: 'account_type',
                        dataIndex: 'account_type',
                        key: 'account_type',
                        scopedSlots: { customRender: 'account_type' },
                    },

                    {
                        title: 'action',
                        dataIndex: 'expired',
                        key: 'expired',
                        scopedSlots: { customRender: 'expired' },
                    },
                    
                    
                ],

                selectedChannelType: 'voice',

                selectedChannelProvider: 'twilio',

                newProjectName: '',

                newProjectDescription: '',

                total_facebookAccounts: 0,

                searchTerm: '',

                current_account_id: null,

                periodic1: null,
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>